<template>
	<div>
			<!-- <UPButton class="customBtn" @click="btnClick" :scope="scope"
				:style="{width:'80px',height:'35px',backgroundColor:'#208349',fontSize:'14px'}">
				立即登录
			</UPButton> -->
			<button class="h5-cup-ui-upbutton up-button" data-scope="scope.mobile,scope.auth" data-timeout="2000">授权button</button>
	</div>
</template>

<script>
	
		import {
			Dialog
		} from "vant";
	
		import upsdk from 'upsdk-vue'
		import appid from '@/utils/config'
		import {
			auth,
			config
		} from "@/api/login"
		import {
			isUnionPay
		} from '@/utils/tools'
		export default {
			
			data() {
				return {
					counts: [],
					userinfo: [],
					show: 0,
					scope: "scope.mobile",
					isToken: this.$store.state.token,
					isUnionPay: isUnionPay()
				}
			},
			mounted() {
			document.getElementsByClassName('h5-cup-ui-upbutton')[0].addEventListener('h5-cup-ui-upbutton-click', function (event) {
			    console.log(event.detail)
			})
	
	       
	
			},
			methods: {
	
				btnClick(event, err, result) {
					console.log(JSON.stringify(result))
					if (result.code) {
						auth({
							code: result.code,
							appid: appid.appId
						}).then((res) => {
							if (res.data.code == 200) {
								window.localStorage.setItem('token', res.data.data.token)
								this.$store.commit('setToken', res.data.data.token)
	
								this.info()
								this.reload()
								// router.push('/mine')
	
							} else {
								this.$toast({
									message: res.data.msg,
								});
							}
						})
					} else {
						this.$toast({
							message: '授权失败',
						});
					}
				},
				// 核销二维码
				examineCode() {
					var that = this
					// if (isUnionPay()) {
						upsdk.pluginReady (function(){
						   upsdk.scanQRCode({
						   	scanType: ["qrCode"],
						   	success: function(result) {
						   		alert(result);
						   		that.$router.push({
						   			path: '/Mine/examineCode',
						   			query: {
						   				data: result
						   			}
						   		})
						   	}
						   })
						});
					
					// }
	
				},
				//获取用户信息
				info() {
					info().then(res => {
						this.userinfo = res.data.data.info
						this.$store.commit('userinfo', res.data.data.info)
						window.localStorage.setItem('info', JSON.stringify(res.data.data.info))
	
					})
				},
				//跳转登录页面
				Login() {
					this.$router.push('/login')
				},
				
				//退出登录
				OutLogin() {
					Dialog.alert({
						closeOnClickOverlay: true,
						message: '确实退出登录吗？',
					}).then(() => {
						window.localStorage.clear()
						this.$router.push('/login')
					});
				}
			}
		}
	
</script>

<style>
</style>
